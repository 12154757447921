<template>
  <div>
    <div class="">
      <span>{{ $t("casbee.label_input_format") }}</span>
      <div>
        <input v-model="selectModel" type="radio" value="条件選択式" name="条件選択式"> 条件選択式
        <input v-model="selectModel" type="radio" value="1次エネルギー消費量自由入力" name="1次エネルギー消費量自由入力"> 条件選択式
      </div>
    </div>
    <component v-if="selectModel !== null" :is="optionComponent" @onHandlerCalculationData="onHandlerCalculationData" />
  </div>
</template>
<script>
import { INPUT_TYPE } from '@/constants/casbee';
export default {
  data() {
    return {
      selectModel: null,
    }
  },
  methods: {
    onHandlerCalculationData(data) {
      this.$emit('onHandlerCalculationData', data);
    }
  },
  computed: {
    optionComponent() {
      let page = null;
      if (this.selectModel === INPUT_TYPE.CHOOSE_CONDITION) {
        page = 'choose-condition';
      } else {
        page = 'enter-freely';
      }
      return () => import(`@/views/emissions/dbCustomize/architecture/apartment/${page}.vue`);
    }
  },
}
</script>